import { index } from "./components/index";
import { tab } from "./components/tab";

index();
tab(".recruitmentpage .occupation");
tab(".recruitmentpage .flow");
tab(".businesspage");

$(function () {
	$(document).on('click', '.anker', function () {
		var hash = $(this.hash);
		if ($(hash).selector == "#noscroll") return false;
		var offset = $(hash).offset().top;
		$("html,body").animate({ scrollTop: offset - $('.header').outerHeight() - 30 }, 800);
		return false;
	});
	$(document).on('click', '.header__bar a', function () {
		$('.header').addClass('nav__active');
		$('.container').addClass('nav__active');
		return false;
	});
	$(document).on('click', '.nav__close a', function () {
		$('.header').toggleClass('nav__active');
		$('.container').toggleClass('nav__active');
		return false;
	});

	//
	$(document).on('click', '.interview__log a', function () {
		$('.interview__list .item').addClass('is__active');
		$('.interview__log').remove();
		return false;
	});

	//

	$(document).on('click', '.recruitmentpage__welfare .item h3', function () {
		$(this).toggleClass('is__active');
		return false;
	});

	$(document).on('click', '.message__container .btn', function () {
		$('.message__container .text').toggleClass('is__active');
		$(this).toggleClass('is__active');
		if ($(this).hasClass('is__active')) {
			$('a', this).html('閉じる');
		} else {
			$('a', this).html('続きを読む');
		}
		return false;
	});
})
