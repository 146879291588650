// const moment = require('moment');
import { Autoplay, EffectFade, Swiper } from 'swiper'
Swiper.use([Autoplay, EffectFade]);
export function index() {
	$(function () {
		// console.log(moment().format("YYYY-MM-DD"));
		if ($(".toppage__mainimage").length) {
			const swiper = new Swiper('.toppage__mainimage .swiper', {
				loop: true,
				effect: "fade",
				fadeEffect: {
					crossFade: true
				},
				centeredSlides: true,
				autoplay: {
					delay: 5000,
					disableOnInteraction: false,
				},
			});
		}
	});
}
